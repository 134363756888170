import { get } from '@/utils/http.js';

/*
function:获取答题解析
author:ysl
time:2021/03/23
*/

export function getAnswerMobileResolution(data: any) {
    return get('/answer/mobile/resolution', data);
}
