













































































































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import { getAnswerMobileResolution } from '@/api/courseWork/myHomework/answerAnalysis.ts';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class AnswerAnalysis extends Vue {
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      ans_id: 0
  };

  // 解析数据
  analysisData = [];
  // 课程名字
  crsName = '';
  // 节名字
  sectionName = '';

  mounted() {
      this.getData();
  }

  // 方法
  getData() {
      this.sectionName = this.$route.query.sectionName as string;
      this.crsName = this.$route.query.crsName as string;
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.ans_id = parseInt(this.$route.query.ansId as string);
      getAnswerMobileResolution(this.data).then((res: any) => {
          console.log(res);
          this.analysisData = res;
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 单选
  radioClass(rightAnswer: string, userAnswer: string, answer: string) {
      if (rightAnswer === answer) {
          return 'item-right';
      } else if (userAnswer === answer) {
          return 'item-error';
      }
  }

  // 多选
  checkboxClass(rightAnswer: any, userAnswer: any, answer: string, length: number) {
      const right = rightAnswer.split(',');
      const user = userAnswer.split(',');
      // 去除用户数组里面的正确答案
      // eslint-disable-next-line array-callback-return
      const list = user.filter((items: any) => {
          if (!right.includes(items)) return items;
      });
      for (let i = 0; i < length; i++) {
          if (right[i] === answer) {
              return 'item-right';
          } else if (list[i] === answer) {
              return 'item-error';
          }
      }
  }

  // 跳转到我的作业
  goMyHomeWork() {
      this.$router.push({
          path: '/myHomework',
          query: {
              crsName: this.$route.query.crsName as string,
              crsId: this.$route.query.crsId as string,
              totalQues: this.$route.query.totalQues as string
          }
      });
  }
}
